<template>

  <div class="custom-image">
    <div class="custom-left">
      <el-tabs :tab-position="tabPosition" style="height: calc(100vh - 350px);" v-model="activeName">
        <el-tab-pane label="addWord" name="addWord">
          <span slot="label" class="leftTab">
            <i class="el-icon-circle-plus-outline"></i>
            <span>添加</span>
          </span>
          <span style="display: flex;justify-content: center;align-items: center;margin-top: 10px">
              <el-button type="info" round style="width: 80%" @click="addWord">添加文字</el-button>
          </span>
        </el-tab-pane>
        <el-tab-pane label="nature" name="nature">
          <span slot="label" class="leftTab">
            <i class="arsenal_icon arsenalshuxing"></i>
            <span>属性</span>
          </span>
          <div class="naturContent" v-if='currentActiveComponent!=null'>


            <div class="natureContent-dropdown">
              <el-select v-model="currentActiveComponent.text.font"
                         @change="changeFont" placeholder="请选择">
                <el-option
                    v-for="item in fontFamilyArr"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>

              <el-input v-model="currentActiveComponent.text.fontSize"
                        @input="(v)=>{fontSizeOnChange(v)}"
                        placeholder="请输入字体大小" type="number" :min="12"></el-input>
            </div>
            <div class="natureContent-color">
              <div>颜色</div>
              <div class="custom-image-prop">
                <div class="text-prop">

                  <div class="color">
                    <input type="color" @input="colorChange" v-model="currentActiveComponent.text.originColor"
                           style="height: 30px"/>
                  </div>
                </div>

              </div>
            </div>
            <div class="natureContent-content">
              <div class="content-input-title">
                <span>内容</span>
                <el-dropdown @command="commandFontColor($event)" trigger="click">
                  <span class="el-dropdown-link">
                    <el-button type="text">插入变量</el-button><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(item,index) in variableList"
                        :key="index"
                        v-text="item.label"
                        :command="composeValue(item.label,item.value)"
                    >
                    </el-dropdown-item>
                  </el-dropdown-menu>

                </el-dropdown>

              </div>
              <div class="content-input">
                <el-input
                    type="textarea"
                    @input="(v)=>{currentActiveComponent.fabric.text=v;canvas.requestRenderAll()}"
                    :rows="4"
                    placeholder="请输入内容"
                    v-model="currentActiveComponent.text.text">
                </el-input>
                <!-- <ckeditor
                    :editor="ckeditor.editor"
                    v-model="currentActiveComponent.text.text"
                    :config="ckeditor.editorConfig"
                    @focus="(zh,editor)=>currentEditor=editor"
                    @ready="(editor)=>currentEditor=editor"
                    style="height: 150px"
                ></ckeditor> -->
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="custom-right">
      <div>
        <canvas v-if='imageloaded' style="border:1px solid #000" id="canvas" :width="width" :height="height"></canvas>
      </div>
      <div class="canvasZoom">
      <div style="margin-bottom: 8px">缩放比例：{{Math.round(zoom*100)}}%</div>
      <div>按住Alt键可以对图片进行拖动</div>
</div>

    </div>


  </div>
</template>
<script>
import CKEDITOR from "ckeditor";


var check = function (img, callback) {
  // 只要任何一方大于0
  // 表示已经服务器已经返回宽高
  console.debug(img, img);
  setTimeout(() => {
    if (img.width > 0 || img.height > 0) {
      if (callback) {
        callback(img.width, img.height);
      }
    }
  },100)

};

function toRBG(color) {
  // 16进制颜色值的正则
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  // 把颜色值变成小写
  color = color.toLowerCase();
  if (reg.test(color)) {
    // 如果只有三位的值，需变成六位，如：#fff => #ffffff
    if (color.length === 4) {
      var colorNew = "#";
      for (var i = 1; i < 4; i += 1) {
        colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
      }
      color = colorNew;
    }
    // 处理六位的颜色值，转为RGB
    var colorChange = [];
    let r = parseInt("0x" + color.slice(1, 3));
    let g = parseInt("0x" + color.slice(3, 5));
    let b = parseInt("0x" + color.slice(5, 7));

    return {r: r, g: g, b: b};
  }
}

function initCanvansEvent(canvas,onZoom){
  canvas.on('mouse:down', function(opt) {
    var evt = opt.e;
    if (evt.altKey === true) {
      this.isDragging = true;
      this.selection = false;
      this.lastPosX = evt.clientX;
      this.lastPosY = evt.clientY;
    }
  });
  canvas.on('mouse:move', function(opt) {
    if (this.isDragging) {
      var e = opt.e;
      var vpt = this.viewportTransform;
      vpt[4] += e.clientX - this.lastPosX;
      vpt[5] += e.clientY - this.lastPosY;
      this.requestRenderAll();
      this.lastPosX = e.clientX;
      this.lastPosY = e.clientY;
    }
  });
  canvas.on('mouse:up', function(opt) {
    // on mouse up we want to recalculate new interaction
    // for all objects, so we call setViewportTransform
    this.setViewportTransform(this.viewportTransform);
    this.isDragging = false;
    this.selection = true;
  });
  canvas.on('mouse:wheel', function(opt) {
    var delta = opt.e.deltaY;
    var zoom = canvas.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom < 0.01) zoom = 0.01;
    canvas.setZoom(zoom);
    opt.e.preventDefault();
    opt.e.stopPropagation();
    if (onZoom){
      onZoom(zoom);
    }
  })

}


export default {
  data() {
    return {
      items: [
        {type: 'text', x: 0, y: 0, text: '', color: {r: 0, g: 0, b: 0}, fontFace: 0, fontSize: 20, fabricText: null}
      ],
      canvas: null,
      currentItemIndex: 0,
      tabPosition: 'left',
      activeName: 'addWord',
      fontSizeNum: '12',
      fontFamilyArr: [{label: '黑体', value: 'simhei'}, {label: '宋体', value: 'simsun'}, {
        label: '楷体',
        value: 'simkai'
      }, {label: '仿宋', value: 'simfang'}],
      ckeditor: {
        editor: CKEDITOR.ClassicEditor,
        editorConfig: {
          toolbar: [],
          askPluginListener: [
            {
              event: "PLACEHOLDER",
              process: (data, callback) => {
                console.debug('editor call back', data, callback)
              },
            }
          ], // 注册监听
        },
      },
      currentEditor: null,
      naturecontent: '',
      fillColor: '',
      fontWord: {
        fillColor: '',
        naturecontent: '',
        fontFamilyStr: '',
        fontSizeNum: '12',
        fontFamilyValue: ''
      },
      isNature: false,
      width: 100,
      height: 100,
      imageloaded: false,
      currentActiveComponent: null,
      variableList: [
        {label: '员工名字', value: '${name}'},
        {label: '入职天数', value: '${si_days}'},
        {label: '入职周数', value: '${si_weeks}'},
        {label: '入职月数', value: '${si_months}'},
        {label: '入职年数', value: '${si_years}'},
      ],
      variable: '插入变量',
      zoom:1.0,
    }
  },
  props: {
    background: {
      type: String,
      default() {
        return ''
      }
    },
    drawnComponents: {
      type: Array,
      default() {
        // [{type:'text',text:{x:0,y:0,text:'content',font:'simhei',fontSize:20,color:{r: 0, g: 0, b: 0}}}]
        return []
      }
    }
  },
  watch: {},
  computed: {
    fontFamilyStr() {
      if (this.fontFamilyArr.length > 0) {
        this.fontWord.fontFamilyStr = this.fontFamilyArr[0].label
        this.fontWord.fontFamilyValue = this.fontFamilyArr[0].value
      }
      console.log(this.fontWord)
    }
  },
  mounted() {
    // colorChange = this.colorChange;
    this.drawCanvas(() => {
      if (this.drawnComponents && this.drawnComponents.length > 0) {
        for (let i = 0, len = this.drawnComponents.length; i < len; i++) {
          this.drawComponent(this.drawnComponents[i]);
        }
      }
    });
  },

  methods: {
    changeFont(font, v) {
      console.debug('font change', font, v);
      this.currentActiveComponent.fabric.set("fontFamily", font);
      this.canvas.requestRenderAll();
    },
    fontSizeOnChange(value) {
      console.debug('font size on change', value);
      this.currentActiveComponent.fabric.set('fontSize', value);
      // this.currentActiveComponent.fabric.fontSize = value;
      this.canvas.requestRenderAll();
    },
    drawComponent(component) {
      let type = component.type;

      let fabricComponent = null;
      if (type === 'text') {
        fabricComponent = this.drawTextComponent(component);
      }

      console.debug('fabricComponent generated', fabricComponent);
      if (fabricComponent) {
        this.canvas.add(fabricComponent);
        component.fabric = fabricComponent;
        fabricComponent.on('selected', (event) => {
          console.debug('fabricComponent on selected', event);
          this.activeName = 'nature'
          this.currentActiveComponent = component
        });
      }

    },
    drawTextComponent(text) {

      var textbox = new fabric.Textbox(text.text.text, {
        left: text.x,
        top: text.y,
        width: 150,
        fontSize: text.text.fontSize
      });

      textbox.fill = text.text.originColor;
      textbox.fontFamily = text.text.font;
      textbox.lockRotation = true;
      textbox.lockScalingY = true;
      textbox.editable = false;
      textbox.on('moving', (event) => {
        text.x = textbox.left;
        text.y = textbox.top;
      })
      return textbox;
    },
    drawCanvas(callback) {
      var img = new Image();
      console.debug('background', this.background);
      img.src = this.background;

      let clientHeight = document.body.clientHeight
      var set = setInterval(check(img, (w, h) => {
        clearInterval(set);


        console.debug('image', w, h);
        let proportion = w / h;
        this.height = clientHeight - 400;
        this.width = this.height * proportion;

        let scale = this.height / h;


        this.imageloaded = true;
        setTimeout(() => {

          this.canvas = new fabric.Canvas('canvas');
          this.canvas.setZoom(scale);
          this.zoom = scale;
          initCanvansEvent(this.canvas,(zoom)=>this.zoom=zoom);
          this.canvas.setBackgroundImage(this.background, this.canvas.renderAll.bind(this.canvas));
          if (callback) {
            callback();
          }
        }, 500);

      }), 40);
    },
    //选择字体颜色
    commandFontColor(item) {
      console.log(item)
      this.fontWord.fontFamilyStr = item.label
      this.fontWord.fontFamilyValue = item.value
      this.variable = item.label
      this.currentActiveComponent.text.text = this.currentActiveComponent.text.text + item.value

      this.currentActiveComponent.fabric.set('text', this.currentActiveComponent.text.text);
      this.canvas.requestRenderAll()


    },
    composeValue(label, value) {
      return {
        'label': label,
        'value': value

      }
    },
    //插入变量
    insertToEditor() {
      console.debug('current editor ', this.currentEditor);
      /* let command = this.currentEditor.commands.get("insertAskComponent");
       command.execute({
         tag: "span",
         options: {
           name: ' {变量} ',
           data: '${customName}',
         },
       });*/
    },
    //选择颜色框时的获取颜色的值
    colorChange(v) {

      let color = toRBG(this.currentActiveComponent.text.originColor);
      console.debug('c v', v, color);
      this.currentActiveComponent.text.color = color;
      this.currentActiveComponent.fabric.set('fill', this.currentActiveComponent.text.originColor);
      this.canvas.requestRenderAll();
    },
    //添加文字
    addWord() {
      let text = {
        type: 'text',
        x: 0,
        y: 0,
        text: {text: '文字内容', font: 'simhei', fontSize: 20, color: {r: 0, g: 0, b: 0}, originColor: "#000000"}
      };
      this.drawnComponents.push(text);
      console.debug('drawnComponents', this.drawnComponents);
      this.drawComponent(text);

      /* this.isNature=true
       this.activeName='nature'*/
    }
  }
}
</script>
<style lang="less">
.custom-image {
  display: flex;

  .custom-left {
    flex: none;
    width: 300px;
    box-shadow: 2px 2px 5px gray;

    .el-tabs--left .el-tabs__header.is-left {
      margin-right: 0px;
    }

    .leftTab {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        line-height: 2;
      }

      i {
        font-size: 20px;
      }

      .arsenal_icon {
        margin-right: 0;
        height: 28px !important;
      }
    }

    .el-tabs__item {
      height: 60px;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .el-button.is-round {
      border-radius: 10px !important;
    }

    .el-button.is-round {
      color: #366AFF;
      font-weight: 600;
      background-color: #F2F2F2;
      border-color: #F2F2F2;
    }
  }

  .custom-right {
    flex: 1;
    background-color: rgba(244, 248, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;

    .custom-right-image {
      /* height: calc(100vh - 450px);*/
      width: 50%;
      min-width: 200px;
      /* height: 80%;*/
      object-fit: contain;
    }
  }

  .naturContent {
    padding: 0 10px;

    .natureContent-dropdown {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 12px;

      .el-dropdown {
        margin-right: 20px;
        width: 100%;
      }

      .el-button.is-round {
        width: 100%;
      }
    }

    .natureContent-color {
      margin-bottom: 12px;

      .custom-image-prop {
        margin-top: 8px;
      }

      .text-prop {
        input {
          width: 100%;
        }
      }
    }

    .natureContent-content {

      .content-input-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;

        .el-dropdown-link {
          cursor: pointer;
        }
      }

      .content-input {
        .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable, .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
          height: 150px !important;
        }
      }
    }

    .el-button.is-round {
      border-radius: 10px !important;
    }

    .el-button.is-round {
      color: black;
      font-weight: 600;
      background-color: #F2F2F2;
      border-color: #F2F2F2;
    }
  }
}
.canvasZoom{
  position: absolute;
  top: 100px;
  right: 10px;
}
</style>